@import "../app.scss";

.portfolio {
  position: relative;

  .progress {
    position: sticky;
    top: 0;
    left: 0;
    padding-top: 90px;
    text-align: center;
    color: #0a101d; // Default color for light mode
    font-size: 36px;

    // Using Tailwind's text colors for dark mode
    @apply text-primary dark:text-white;

    @include mobile {
      padding-top: calc(10vh - 10px);
      font-size: 24px;
    }

    .progressBar {
      height: 5px;
      @apply bg-primary dark:bg-white; // Progress bar background colors swapped
    }
  }

  .work-section {
    scroll-snap-align: center;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: 50px;

    .wrapper {
      max-width: 1366px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;

      @include mobile {
        flex-direction: column;
      }

      .imageContainer {
        flex: 1;
        height: 50%;

        @include mobile {
          width: 100%;
          max-height: 300px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          @include mobile {
            object-fit: contain;
          }
        }
      }

      .textContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include mobile {
          transform: none !important;
          padding: 10px;
          align-items: center;
          text-align: center;
        }

        h2 {
          font-size: 52px;

          @include mobile {
            font-size: 36px;
          }
        }

        p {
          color: gray;
          font-size: 18px;

          @include mobile {
            font-size: 16px;
          }
        }

        button {
          background-color: #C47124;
          border: none;
          border-radius: 10px;
          padding: 10px;
          width: 200px;
          cursor: pointer;
        }
      }
    }
  }
}
