* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background-color: black; */
    
}

::-webkit-scrollbar {
    width: 6px; 
  }
  
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .pointer {
    cursor: pointer;
  }

  .rotate-effect {
    transform: rotate(-5.20deg); 
  }
  
  

  

