/* Add these styles to your CSS file */

@keyframes move-bubble1 {
    0% {
      transform: translate(-10%, -10%);
    }
    25% {
      transform: translate(30%, -40%);
    }
    50% {
      transform: translate(10%, 20%);
    }
    75% {
      transform: translate(-30%, 40%);
    }
    100% {
      transform: translate(-10%, -10%);
    }
  }
  
  @keyframes move-bubble2 {
    0% {
      transform: translate(0%, 0%);
    }
    25% {
      transform: translate(-25%, 50%);
    }
    50% {
      transform: translate(30%, -10%);
    }
    75% {
      transform: translate(10%, -50%);
    }
    100% {
      transform: translate(0%, 0%);
    }
  }
  
  @keyframes move-bubble3 {
    0% {
      transform: translate(10%, -10%);
    }
    25% {
      transform: translate(-20%, 30%);
    }
    50% {
      transform: translate(20%, -20%);
    }
    75% {
      transform: translate(-10%, 50%);
    }
    100% {
      transform: translate(10%, -10%);
    }
  }
  
  .animate-move-bubble1 {
    animation: move-bubble1 15s linear infinite;
  }
  
  .animate-move-bubble2 {
    animation: move-bubble2 20s linear infinite;
  }
  
  .animate-move-bubble3 {
    animation: move-bubble3 25s linear infinite;
  }
  