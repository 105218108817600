.hidden-text {
  padding: 6% 15%;
}

.hidden-text span {
  color: hsla(0, 0%, 0%, 0.7);
  background-clip: text;
  background-repeat: no-repeat;
  background-size: 0% 100%; 
  background-image: linear-gradient(90deg, black, black);
}

/* Dark mode */
.dark .hidden-text span {
  color: hsla(0, 0%, 100%, 0.127);
  background-image: linear-gradient(20deg, white, white);
}

.reveal span {
  background-size: 100% 100%; 
}

.hidden-text h1 {
  margin-bottom: 0.5rem; 
}

.hidden-text h1 span {
  font-size: 2.2rem;
  font-weight: bold;
}

.hidden-text p span {
  font-size: 1.1rem;
}

.skills {
  padding: 0% 15%;
}

.skills .grid > div {
  margin-bottom: 0.2rem; /* Space between rows of skills */
}

@media (max-width: 768px) {
  .hidden-text {
    padding: 15% 1%; 
    text-align: center;
  }
  .hidden-text h1 {
    margin-bottom: 1rem; 
  }

  .skills .grid > div {
    margin-bottom: 1.3rem; /* Space between rows of skills */
  }

  .hidden-text h1 span {
    font-size: 1.8rem; /* Smaller font for headers on mobile */
  }

  .hidden-text p span {
    font-size: 1.02rem; /* Slightly smaller font for paragraphs */
  }

  .skills {
    padding: 6% 5%; /* Less padding on mobile */
  }

  /* Set skills grid to two columns on mobile */
  .skills .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
