.bttnn,
.bttnn:link,
.bttnn:visited {
  font-size: 1.7rem;
  padding: 1.4rem 3rem;
  border-radius: 10rem;
  display: inline-block;
  text-decoration: none;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.bttnn:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
}

.bttnn:active {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.bttnn--green {
  background-color: black;
  color: white;
}

.bttnn--gray {
  background-color: rgb(78, 78, 78);
  color: white;
}

.bttnn--none {
  border: 1px solid rgb(199, 198, 198)!important;
  color: rgb(68, 68, 68);
}

.bttnn--green::after {
  background-color: #55c57a;
}

.bttnn--small {
  padding: 0.3rem 1.5rem !important;
  font-size: 0.9rem !important;
  margin: 0 20px 50px;
}

.bttnn--small:hover {
  border: 1px solid black;
}

.bttnn-text:link,
.bttnn-text:visited {
  color: #55c57a;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #55c57a;
  padding: 3px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.bttnn-text:hover {
  background-color: #55c57a;
  color: #fff;
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.bttnn-text:active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.bttnn-text:focus {
  outline: none;
  outline: 3px solid #55c57a;
  outline-offset: 3px;
}


header {
  margin: 0;
  padding: 0;
  width: 100vw; 
  left: 0; 
  right: 0; 
  overflow-x: hidden; 
}

nav {
  padding-left: 0; 
  padding-right: 0; 
  margin: 0; 
}


