html {
  scroll-snap-type: y proximity;
  scroll-behavior: smooth;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

section {
  height: calc(100vh - 20px);
  width: 100vw;
  scroll-snap-align: start;
  overflow-y: auto;
}

.work-scroll-container {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.work-section {
  scroll-snap-align: center;
}

@mixin mobile {
  @media (max-width: 738px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1366px) {
    @content;
  }
}
